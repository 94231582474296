import React from 'react';
import {Datagrid, DateField, Filter, List, ReferenceField, SelectField, TextField, TextInput} from 'react-admin';

import AttendanceResultStatuses from './AttendanceResultStatuses'

const AttendanceResultSummaryFilter = (props) => (
  <Filter {...props}>
    <TextInput label="ra.action.search" source="q" alwaysOn />
  </Filter>
);

export const AttendanceResultSummaryList = props => (
  <List filters={<AttendanceResultSummaryFilter />} bulkActionButtons={false} {...props} perPage={25} exporter={false}>
    <Datagrid rowClick="edit" >
      <DateField source="summaryDate" />
      <ReferenceField source="unitId" reference="baseUnits" allowEmpty>
        <TextField source="name" />
      </ReferenceField>
      <TextField source="name" />
      <SelectField source="authStatus" choices={AttendanceResultStatuses} {...props} />
        <TextField source="authorizedTime"/>
    </Datagrid>
</List>
);

export default AttendanceResultSummaryList
