import React, {useState} from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';

import {DateInput, required, Title} from 'react-admin';
import {downloadFile, ReportForm} from '../../components';
import moment from "moment";
import env from "../../env";

const EmployeeOffRequestReport = (props) => {

    const [formValues, setFormValues] = useState({
        fromDate: Date.now(),
        toDate: Date.now()
    })

    const validate = (values) => {
        const errors = {};
        setFormValues(values);
        return errors;
    };

    const excelReport = () => {
        const params = new URLSearchParams({
            fromDate: moment(formValues.fromDate).format('YYYY-MM-DD'),
            toDate: moment(formValues.toDate).format('YYYY-MM-DD'),
        }).toString()
        downloadFile(`${env.reportURL}/reports/employee-off-request?${params}`, 'tong_hop_don_xin_nghi.xlsx')
    }

    return (
        <Card>
            <Title title="resources.employee-off-request-report.name"/>
            <CardContent>
                <ReportForm params={formValues}
                            validate={validate}
                            excelReport={excelReport}
                >
                    <Grid container spacing={8}>
                        <Grid item xs={12} sm={6}>
                            <DateInput label="resources.common.fromDate" source="fromDate"
                                       validate={required()}
                                       inputProps={{max: moment().format('YYYY-MM-DD')}}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DateInput label="resources.common.toDate" source="toDate"
                                       validate={required()}
                                       inputProps={{max: moment().format('YYYY-MM-DD')}}/>
                        </Grid>
                    </Grid>
                </ReportForm>
            </CardContent>
        </Card>
    );
};

export default EmployeeOffRequestReport;
