import React from 'react';
import {Redirect} from 'react-router-dom';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {Button, Grid} from '@material-ui/core'
import {
    AutocompleteArrayInput,
    AutocompleteInput,
    BooleanInput,
    CheckboxGroupInput,
    DateInput,
    ReferenceArrayInput,
    ReferenceInput,
    required,
    showNotification,
    TextInput,
    translate
} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import TreeCheckBoxCustomInput from "../../components/TreeCheckBoxCustomInput";
import cachedApis from "../../api/cachedApis";
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import SingleSelectComponent from "../../components/SingleSelectComponent";
import {DaysOfWeekCheckboxInput, schoolYears,} from "../../components";
import WhiteListDialog from "./WhiteListDialog";
import axiosInstance from "../../api/axios";
import TreeCheckBoxUnitInput from "../StudentEnrollment/TreeCheckBoxUnitInput";
import {eventTypes} from "../../components/EventType";

const styles = {
    button: {
        margin: 1,
    },
    cancelBtn: {
        marginLeft: 20
    },
    checkboxGroup: {
        "& > div ": {
            flexDirection: 'column',
        }
    },
    customCheckBox: {
        "& > div > label ": {
            width: '100%'
        }

    }
};

const daysOfWeek = [
    {id: 'DAILY', value: 'Hằng ngày'},
    {id: 'WEEKLY', value: 'Hằng tuần'},
]

const EventFields = props => {
    const [values, setValues] = React.useState({
        isRedirect: false,
        refreshList: false,
        openWhiteList: false,
        units: [],
        deviceOutIds: []
    });
    const [customers, setCustomers] = React.useState([]);
    const {defaultValue, save} = props;

    React.useEffect(() => {
        if (!defaultValue.isRedirect) {
            getCustomersWithUnits();
            if (schoolYears && schoolYears.length > 0) {
                getUnits(schoolYears[0].id);
            } else {
                getUnits(null);
            }
            setValues({...values, deviceOutIds: defaultValue.deviceOutIds})
        } else {
            setValues({isRedirect: true, refreshList: true});
        }
    }, [defaultValue.isRedirect]);

    function getCustomersWithUnits() {
        cachedApis.getCustomerUnits(false).then(rs => {
            setCustomers(rs);
        })
    }

    function getUnits(schoolYear) {
        let url;
        if (schoolYear) {
            url = `/api/units/search/filter?clazzWithParents=false&page=0&size=999&sort=id%2CASC&schoolYear=${schoolYear}`;
        } else {
            url = `/api/units/search/filter?clazzWithParents=false&page=0&size=999&sort=id%2CASC`;
        }
        return axiosInstance.get(url).then(rs => {
            setValues({...values, units: rs.data.content})
        }, err => {
            props.showNotification(err.message, 'warning');
        });
    }

    function cancel() {
        setValues({isRedirect: true, refreshList: false});
    }

    function openWhiteList() {
        setValues({...values, openWhiteList: true})
    }

    function closeWhiteList() {
        setValues({...values, openWhiteList: false})
    }

    if (values.isRedirect) {
        return <Redirect to={'/events?refreshView=' + values.refreshList}/>;
    } else {
        return (
            <React.Fragment>
                <Grid item xs={12} sm={6}>
                    <SingleSelectComponent label={'Loại sự kiện'}
                                           choices={eventTypes}
                                           selectedData={defaultValue.type}
                                           onChange={(e) => props.onDirectChange(
                                               'type', e)}/>
                </Grid>
                <Grid item xs={12} sm={6}>
                    {
                        ["ATTENDANCE"].includes(defaultValue.type) && <>
                            <BooleanInput source="automaticAuthorization"
                                          label="resources.events.fields.automaticAuthorization"
                                          onChange={props.onChange('automaticAuthorization')}/>
                        </>
                    }
                </Grid>
                {/* KEEP this to display device out ids*/}
                <div style={{display: 'none'}}>
                    <ReferenceArrayInput
                        source="deviceOutIds"
                        label="resources.events.fields.deviceOutIds"
                        reference="accessDevices"  {...props}
                    >
                        <AutocompleteArrayInput/>
                    </ReferenceArrayInput>
                    <TextInput type="number" source="quota"
                               label="resources.events.fields.quota"/>
                    <ReferenceInput source="kiotConfigId"
                                    label="resources.events.fields.kiotConfigId"
                                    reference="kiotConfigs"
                                    {...props}>
                        <AutocompleteInput source="name"/>
                    </ReferenceInput>
                </div>


                <Grid item xs={12} sm={6}>
                    <TextInput label="resources.events.fields.name"
                               defaultValue={defaultValue.name}
                               source="name"
                               validate={required()}
                               style={{width: '90%'}}
                               onChange={props.onChange('name')}/>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <BooleanInput source="enabled"
                                  label="resources.events.fields.enabled"
                                  onChange={props.onChange('enabled')}/>
                </Grid>
                {defaultValue.type === 'FULL_DAY_MEAL' &&
                <Grid item xs={12} sm={6}>
                    <BooleanInput source="fullDay"
                                  label="resources.events.fields.fullDay"
                                  onChange={props.onChange('fullDay')}/>

                </Grid>
                }
                {defaultValue.type === 'ATTENDANCE' &&
                <>
                    <Grid item xs={12} sm={6}>
                        <BooleanInput label="resources.events.fields.authorizeAttendance"
                                      source="authorizeAttendance"
                                      onChange={props.onChange('authorizeAttendance')}/>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <BooleanInput label="resources.events.fields.accessControlOnly"
                                      source="accessControlOnly"
                                      onChange={props.onChange('accessControlOnly')}/>
                    </Grid>

                </>
                }
                {defaultValue.type === 'LIMIT_QUANTITY' &&
                <Grid item xs={12} sm={6}>
                    <TextInput type="number" source="quota"
                               label="resources.events.fields.quota"
                               validate={required()}
                               onChange={props.onChange('quota')}/>
                </Grid>
                }
                <Grid item xs={12} sm={6}>
                    <BooleanInput label="resources.events.fields.recurring"
                                  source="recurring"
                                  onChange={props.onChange('recurring')}/>
                </Grid>
                {(defaultValue.type === 'ATTENDANCE' || defaultValue.type === 'NAP') &&
                <Grid item xs={12} sm={6}>
                </Grid>
                }
                {defaultValue.type === 'LEAVE' &&
                    <Grid item xs={12} sm={6}>
                        <BooleanInput label="resources.events.fields.accessControlOnly"
                                      source="accessControlOnly"
                                      onChange={props.onChange('accessControlOnly')}/>
                    </Grid>
                }
                {defaultValue.recurring &&
                    <Grid item xs={12} sm={6}>
                        <SingleSelectComponent label={'Chu kỳ lặp lại'}
                                           choices={daysOfWeek}
                                           selectedData={defaultValue.recurringType}
                                           onChange={(e) => props.onDirectChange(
                                               'recurringType', e)}/>
                </Grid>
                }


                {defaultValue.recurring &&
                <>
                    {defaultValue.recurringType === 'WEEKLY' ?
                        <Grid item xs={12} sm={6}>
                            <DaysOfWeekCheckboxInput source="daysOfWeek" {...props}
                                                     label="resources.events.fields.daysOfWeek"
                                                     onChange={(e,
                                                                v) => props.onDirectChange(
                                                         'daysOfWeek', v)}
                                                     className={props.classes.checkboxGroup}
                            />
                        </Grid>
                        : <Grid item xs={12} sm={6}>
                        </Grid>
                    }
                </>
                }
                {defaultValue.type === 'KIOT_MEAL' &&
                <>
                    <Grid item xs={12} sm={6}>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <ReferenceInput source="eventGroupId"
                                        label="resources.events.fields.eventGroupId"
                                        reference="eventGroups"
                                        allowEmpty
                                        {...props}
                                        onChange={(e, v) => props.onDirectChange(
                                            'eventGroupId', v)}>
                            <AutocompleteInput source="name"/>
                        </ReferenceInput>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <ReferenceInput source="kiotConfigId"
                                        label="resources.events.fields.kiotConfigId"
                                        reference="kiotConfigs"
                                        allowEmpty
                                        {...props}
                                        onChange={(e, v) => props.onDirectChange(
                                            'kiotConfigId', v)}>
                            <AutocompleteInput source="name"/>
                        </ReferenceInput>
                    </Grid>
                </>

                }
                <Grid item xs={12} sm={6}>
                    <DateInput source="startDate"
                               label="resources.events.fields.startDate"
                               onChange={(e, v) => props.onDirectChange('startDate', v)}
                               validate={required()}/>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <DateInput source="endDate"
                               validate={required()}
                               label="resources.events.fields.endDate"
                               onChange={(e, v) => props.onDirectChange('endDate', v)}/>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextInput type="time"
                               source="startTime"
                               style={{width: 133}}
                               label={defaultValue.type === 'LEAVE' ? 'Thời gian bắt đầu' :"resources.events.fields.startTime"}
                               onChange={(e, v) => props.onDirectChange('startTime',
                                   v)}/>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextInput type="time"
                               source="endTime"
                               style={{width: 133}}
                               label={defaultValue.type === 'LEAVE' ? 'Thời gian kết thúc' : "resources.events.fields.endTime"}
                               onChange={(e, v) => props.onDirectChange('endTime', v)}/>
                </Grid>

                {defaultValue.type === 'ATTENDANCE' &&
                <>
                    <Grid item xs={12} sm={6}>
                        <BooleanInput source="needCheckIn"
                                      label="resources.events.fields.needCheckIn"
                                      onChange={(e, v) => props.onDirectChange(
                                          'needCheckIn', v)}/>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextInput type="number"
                                   source="lateComePermit"
                                   label="resources.events.fields.lateComePermit"
                                   validate={required()}
                                   onChange={props.onChange('lateComePermit')}/>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextInput type="number" source="openForCheckIn"
                                   label="resources.events.fields.openForCheckIn"
                                   validate={required()}
                                   onChange={props.onChange('openForCheckIn')}/>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextInput type="number" source="endForCheckIn"
                                   label="resources.events.fields.endForCheckIn"
                                   validate={required()}
                                   onChange={props.onChange('endForCheckIn')}/>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <BooleanInput source="needCheckOut"
                                      label="resources.events.fields.needCheckOut"
                                      onChange={(e, v) => props.onDirectChange(
                                          'needCheckOut', v)}/>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextInput type="number" source="earlyLeavePermit"
                                   label="resources.events.fields.earlyLeavePermit"
                                   validate={required()}
                                   onChange={props.onChange('earlyLeavePermit')}/>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextInput type="number" source="openForCheckOut"
                                   label="resources.events.fields.openForCheckOut"
                                   validate={required()}
                                   onChange={props.onChange('openForCheckOut')}/>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextInput type="number" source="endForCheckOut"
                                   label="resources.events.fields.endForCheckOut"
                                   validate={required()}
                                   onChange={props.onChange('endForCheckOut')}/>
                    </Grid>
                </>
                }
                {(defaultValue.type !== 'NAP') &&
                <Grid item xs={12} sm={6} style={{maxHeight: '350px', marginTop: 30}}>
                    <div style={{maxHeight: '300px', overflow: 'auto'}}>
                        <ReferenceArrayInput
                            className={props.classes.noLabel}
                            label="resources.events.fields.deviceIds"
                            source="deviceIds"
                            filter={{filterPath: 'findByEnabledIsTrue'}}
                            validate={required()}
                            reference="accessDevices" {...props}
                            onChange={(e, v) => props.onDirectChange(
                                'deviceIds', v)}>
                            <CheckboxGroupInput className={props.classes.customCheckBox}/>
                        </ReferenceArrayInput>
                    </div>
                </Grid>
                }
                {defaultValue.type === 'LIMIT_QUANTITY' &&
                <Grid item xs={12} sm={6}>
                    <ReferenceArrayInput
                        source="deviceOutIds"
                        label="resources.events.fields.deviceOutIds"
                        validate={required()}
                        reference="accessDevices" {...props}
                        onChange={(e, v) => props.onDirectChange('deviceOutIds', v)}>
                        <AutocompleteArrayInput/>
                    </ReferenceArrayInput>
                </Grid>
                }

                <Grid item xs={12} sm={6} style={{maxHeight: '350px', marginTop: 30}}>
                    <label><b>Đơn vị tham gia (ưu tiên 1)</b></label>
                    <div style={{maxHeight: '300px', overflow: 'auto', marginTop: 10}}>
                        <TreeCheckBoxUnitInput data={values.units}
                                               expandTopLevel={true}
                                               selected={defaultValue.unitIds}
                                               onChange={props.onChange('tmpUnitIds')}/>
                    </div>
                </Grid>


                {['ATTENDANCE', 'LEAVE'].includes(defaultValue.type) && <>
                    <Grid item xs={12} sm={6} style={{maxHeight: '350px', marginTop: 30}}>
                        <label><b>Đơn vị loại trừ (ưu tiên 2)</b></label>
                        <div style={{maxHeight: '300px', overflow: 'auto', marginTop: 10}}>
                            <TreeCheckBoxUnitInput data={values.units}
                                                   expandTopLevel={true}
                                                   selected={defaultValue.excludeUnitIds}
                                                   onChange={props.onChange(
                                                       'tmpExcludeUnitIds')}/>
                        </div>
                    </Grid>

                    <Grid item xs={12} sm={6} style={{maxHeight: '350px', marginTop: 30}}>
                        <label><b>Người tham dự (ưu tiên 3)</b></label>
                        <div style={{maxHeight: '300px', overflow: 'auto', marginTop: 10}}>
                            <TreeCheckBoxCustomInput data={customers}
                                                     selected={defaultValue.customerIds}
                                                     expandTopLevel={true}
                                                     onChange={props.onChange('cuzIds')}/>
                        </div>
                    </Grid>
                </>}

                {defaultValue.type === 'ATTENDANCE' &&
                <>

                    <Grid item xs={12} sm={6}>
                        <ReferenceInput source="eventGroupId"
                                        label="resources.events.fields.eventGroupId"
                                        reference="eventGroups"
                                        allowEmpty
                                        {...props}
                                        onChange={(e, v) => props.onDirectChange(
                                            'eventGroupId', v)}>
                            <AutocompleteInput source="name"/>
                        </ReferenceInput>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <ReferenceInput source="eventLocationId"
                                        label="resources.events.fields.eventLocationId"
                                        reference="eventLocations"
                                        allowEmpty
                                        {...props}
                                        onChange={(e, v) => props.onDirectChange(
                                            'eventLocationId', v)}>
                            <AutocompleteInput source="name"/>
                        </ReferenceInput>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextInput type="text" source="infoLink"
                                   style={{width: '90%'}}
                                   label="resources.events.fields.infoLink"
                                   onChange={props.onChange('infoLink')}/>
                    </Grid>
                </>
                }
                {defaultValue.type === 'FULL_DAY_MEAL' &&
                <>
                    <Grid item xs={12} sm={6} style={{maxHeight: '350px', marginTop: 30}}>
                        <label><b>Người tham dự</b></label>
                        <div style={{maxHeight: '300px', overflow: 'auto', marginTop: 10}}>
                            <TreeCheckBoxCustomInput data={customers}
                                                     selected={defaultValue.customerIds}
                                                     expandTopLevel={true}
                                                     onChange={props.onChange('cuzIds')}/>
                        </div>
                    </Grid>
                </>
                }

                <Grid item xs={12} sm={12}>
                    <ReferenceArrayInput source="sourceEventIds"
                                         label="resources.events.fields.sourceEventIds"
                                         reference="events" {...props}
                                         onChange={(e, v) => props.onDirectChange(
                                             'sourceEventIds', v)}>
                        <AutocompleteArrayInput/>
                    </ReferenceArrayInput>
                </Grid>

                <Grid item xs={12} sm={12} style={{marginTop: 50}}>
                    {(defaultValue.tmpUnitIds.length > 0 || defaultValue.cuzIds.length
                            > 0) &&
                        <Button variant="outlined" color="primary"
                                onClick={openWhiteList}>
                            Danh sách tham dự
                        </Button>
                    }
                </Grid>

                <WhiteListDialog close={closeWhiteList}
                                 open={values.openWhiteList}
                                 unitIds={defaultValue.tmpUnitIds}
                                 customerIds={defaultValue.cuzIds}
                                 excludeUnitIds={defaultValue.tmpExcludeUnitIds}/>

                <Grid item xs={12} sm={12} style={{marginTop: 15}}>
                    <Button variant="contained"
                            color="primary"
                            style={styles.button}
                            disabled={defaultValue.saveDisabled}
                            onClick={save}>
                        <SaveIcon/> Lưu
                    </Button>
                    <Button variant="contained" style={styles.cancelBtn}
                            onClick={cancel}>
                        <CancelIcon/> Hủy
                    </Button>
                </Grid>
            </React.Fragment>
        )
    }
}

export default compose(
    translate,
    connect(null,
        {
            showNotification
        }
    ),
    withStyles(styles)
)(EventFields)
